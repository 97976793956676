import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList(surveyId) {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [

    { key: 'question', class: "col-5", label: 'Question', sortable: false },
    { key: 'questionType', class: "col-1", label: 'Question Type', sortable: false },
    { key: 'questionAnswers', class: "col-5", label: 'Answer / Options', sortable: false },

    { key: 'status', class: "col-1", sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalQuestions = ref(0)
  const userExamId = router.currentRoute.params.id
  const examData = ref(null);
  const examStatus = ref(null)
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchSurveyQuestions = (ctx, callback) => {

    //console.log("loading questions with Id "+surveyId);
    store
      .dispatch('app-members-learning/fetchUserExamQuestions', { id: userExamId },
      )
      .then(response => {


        const { data } = response.data
        examStatus.value = response.data.examStatus
        //console.log("resolved Data");;
        //console.log(response.data);
        totalQuestions.value = data.length;
        examData.value = data
        callback(data)
        totalUsers.value = data.length;

      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching courses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    //console.log("status "+status);
    if (status === 0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusVariantText = status => {
    if (status === 400) return 'deleted'
    if (status === 1) return 'published'
    if (status === 0) return 'draft'
    return 'primary'
  }

  const resolvePageroute = coursemode => {
    if (coursemode === 'IN-PERSON') return 'course-sessions'
    if (coursemode === 'ONLINE') return 'course-lessons'
    if (coursemode === 'HYBRID') return 'course-lessons'

    return 'course-lessons'
  }
  const resolveCourseContent = coursemode => {
    if (coursemode === 'IN-PERSON') return 'Sessions'
    if (coursemode === 'ONLINE') return 'Lectures'
    if (coursemode === 'HYBRID') return 'Lectures'

    return 'Lectures'
  }


  const resolveSurveyStatus = status => {
    if (status === 0) return 'draft'
    if (status === 50) return 'ongoing'
    if (status === 100) return 'completed'
    if (status === 200) return 'canceled'
    if (status === 300) return 'terminated'
    return 'unknown'
  }
  const resolveSurveyStatusVariant = status => {
    //console.log("status "+status);
    if (status === 0) return 'light-secondary'
    if (status === 50) return 'light-warning'
    if (status === 100) return 'light-success'
    if (status === 200) return 'light-dark'
    if (status === 300) return 'light-dark'

    return 'primary'
  }


  return {
    fetchSurveyQuestions,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,
    examData,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    resolvePageroute,
    resolveCourseContent,
    resolveSurveyStatus,
    resolveSurveyStatusVariant,
    totalQuestions,
    examStatus,

  }
}
